import styled from 'styled-components';
import { colours } from '../../../assets/theme';

export const Container = styled.div`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

export const Particle = styled.div`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  background-color: ${props => props.colour || props.theme?.foreground};
  position: absolute;
  z-index: 4;
`;
