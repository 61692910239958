export default {
  name: 'Loose Antenna',
  streamEndpoint: 'https://stream.looseantenna.fm/radio/8000/default.mp3',
  broadcastServerApi: 'https://stream.looseantenna.fm/api',
  stationId: '1',
  cmsUrl: 'https://cms.looseantenna.fm',
  baseUrl: 'https://looseantenna.fm',
  discordInvite: 'https://discord.gg/4fZQAwrwXE',
  peertubeRoot: 'https://tv.undersco.re',
  livestreamId: 'b66c9e71-9460-44c2-bb64-b03e970877f8',
};
