/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
// import { nanoid } from 'nanoid';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import ReactHowler from 'react-howler';
import {use} from 'react-use'

import PlaySVG from '../../icons/Play';
import StopSVG from '../../icons/Stop';
import { P, Span } from '../../atoms/Text';

import { Bar as StyledBar, StandaloneBar, IconContainer } from './styles';
import { usePlayerStore, useLangStore, usePlaybackStore } from '../../../store';

import { useInterval } from '../../../hooks/TimerHooks';

import { colours, textSizes } from '../../../assets/theme';
import config from '../../../../config';

import { seconds } from '../../../helpers/time';
import { useTranslation } from '../../../hooks/TranslationHooks';
import ParticleLoader from '../../atoms/ParticleLoader';

const AnimatedBar = animated(StyledBar);
const AnimatedStandaloneBar = animated(StandaloneBar);
const fast = { mass: 1, tension: 1200, friction: 40 };

const navigatorGlobal = typeof navigator !== 'undefined' && navigator;
const windowGlobal = typeof window !== 'undefined' && window;

const Playbar = ({ standalone, colour }) => {
  const [streamUrl, setStreamUrl] = useState('');
  const player = useRef(null);
  const nowPlaying = usePlayerStore(props => props.nowPlaying);
  const setNowPlaying = usePlayerStore(props => props.setNowPlaying);
  const isPlaying = usePlayerStore(props => props.isPlaying);
  const isLive = usePlayerStore(props => props.isLive);
  const setIsLive = usePlayerStore(props => props.setIsLive);
  const setIsPlaying = usePlayerStore(props => props.setIsPlaying);
  const isLoading = usePlayerStore(props => props.isLoading);
  const setIsLoading = usePlayerStore(props => props.setIsLoading);
  // const sound = usePlayerStore(props => props.sound);
  // const setSound = usePlayerStore(props => props.setSound);
  const currentLang = useLangStore(store => store.currentLang);
  const playbackPlaying = usePlaybackStore(props => props.slug) !== '';
  const setPlaybackSlug = usePlaybackStore(props => props.setSlug);
  const isFirefox = !!navigatorGlobal?.userAgent?.search("Firefox")

  const setlistenerCount = usePlayerStore(store => store.setlistenerCount);

  const nowPlayingText = useTranslation({ key: 'now' })[currentLang];
  const replayText = useTranslation({ key: 'replay' })[currentLang];

  const Bar = standalone ? AnimatedStandaloneBar : AnimatedBar;

  useEffect(() => {
    getNowPlaying();

    if (isFirefox) { // it wouldn't break chrome, but I haven't tested exhaustively
      windowGlobal.Howler._canPlayEvent = 'canplay' // fixes the "wait for file complete downloaded"
    }
    
  }, []);

  const getNowPlaying = async () => {
    try {
      const {
        data: {
          station: { listen_url },
          live: { is_live },
          now_playing: {
            song: { text, title, artist },
          },
          listeners: { current: currentListeners },
        },
        data: {station },
      } = await axios.get(`${config.broadcastServerApi}/nowplaying/${config.stationId}`);

      const playbarText = artist && title ? `${artist} — ${title}` : title;

      // const defaultUrl = station.mounts[0].url
      // const hiQUrl = station.mounts[1].url
      // const lowQUrl = station.mounts[3].url
      // const lowQ = station.mounts[3]
      // const hiQ = station.mounts[1]

      setIsLive(is_live);
      setNowPlaying(playbarText);
      setlistenerCount(currentListeners);

      if (streamUrl === '') {
        console.log('Direct listen URL: ', listen_url);
        setStreamUrl(listen_url);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  useInterval(() => {
    getNowPlaying();
  }, seconds(10));

  const handleAudioStart = () => {
    setIsLoading(false);
    setPlaybackSlug('');
    setIsPlaying(true);
  };

  const handlePlayStopPress = () => {
    if (isPlaying || isLoading) {
      setIsLoading(false);
      setIsPlaying(false);
      player.current.stop();
    } else {
      setIsPlaying(true);
      setIsLoading(true);
    }
  };

  const animatedBarStyle = useSpring({
    height: playbackPlaying ? 60 : 50,
    config: fast,
  });

  return (
    <Bar
      onClick={handlePlayStopPress}
      isPlaying={isPlaying}
      isLoading={isLoading}
      data-hoverable={!isLoading}
      style={animatedBarStyle}
      colour={colour}
    >
      {streamUrl && (
        <ReactHowler
          src={streamUrl}
          playing={isPlaying}
          preload={false}
          html5
          onPlay={handleAudioStart}
          onPause={() => player.current.stop()}
          ref={player}
        />
      )}
      <PlayContents playing={isPlaying} loading={isLoading || streamUrl === ''} colour={colour} standalone={standalone} />
      {nowPlaying && (
        <>
          <P
            css={`
              max-width: 100%;
              text-overflow: ellipsis;
              display: block;
              overflow: hidden;
              white-space: nowrap;
            `}
            size={17}
            title={nowPlaying}
          >
            <Span weight="600" selectable={false} className="playbar--play-status" colour={colour}>{`${nowPlayingText}: `}</Span>
            <Span weight={standalone ? '600' : '300'} className="playbar--now-playing" colour={colour}>{`${nowPlaying}`}</Span>
          </P>

          {isLive ? (
            <Span weight="700" size={textSizes.xs} colour={colours.red} className="playbar--playback-type">
              [ LIVE ]
            </Span>
          ) : (
            <Span weight="700" size={textSizes.xs} colour={standalone ? (colour) : colours.blue} className="playbar--playback-type">
              [ {replayText.toUpperCase()} ]
            </Span>
          )}
        </>
      )}
    </Bar>
  );
};

const PlayContents = ({ playing, loading, standalone, colour }) => {
  if (loading) {
    return (
      <IconContainer>
        <ParticleLoader particles={10} size={24} colour={standalone && (colour || colours.white)} />
      </IconContainer>
    );
  }

  return (
    <IconContainer>
      {playing ? <StopSVG colour="transparent" /> : <PlaySVG colour="transparent" />}
    </IconContainer>
  );
};

Playbar.defaultProps = {};

Playbar.propTypes = {};

export default Playbar;
