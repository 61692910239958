import React from 'react';
import { useMedia } from 'react-use';
import Modal from '../Modal';
import Checkbox from '../../atoms/Checkbox';
import { useUiStore, useUserSettingsStore } from '../../../store';
import Radio from '../../atoms/RadioSelect';
import { GroupLabel } from '../../atoms/RadioSelect/styles';

const SettingsModal = ({ isOpen, close }) => {
  const animations = useUserSettingsStore(store => store.animations);
  const toggleAnimations = useUserSettingsStore(store => store.toggleAnimations);
  const cursorActive = useUserSettingsStore(store => store.animatedCursor);
  const toggleCursor = useUserSettingsStore(store => store.toggleAnimatedCursor);
  const setTheme = useUiStore(store => store.setTheme);
  const displayMode = useUserSettingsStore(store => store.displayMode);
  const setDisplayMode = useUserSettingsStore(store => store.setDisplayMode);
  const isDark = useMedia('(prefers-color-scheme: dark)');

  const handleDisplayChange = (value) => {
    if(value === 'auto') {
      setTheme(isDark ? 'dark' : 'light');
    } else {
      console.log({ value })
      setTheme(displayMode);
    }
    setDisplayMode(value)
  }

  const displayOptions = [{ value: 'auto', label: 'Auto' }, { value: 'dark', label: 'Dark' }, { value: 'light', label: 'Light' }];
  const qualityOptions = [{ value: 'low', label: 'Eco [96kb/s]' }, { value: 'default', label: 'Standard [128kb/s] – recommended' }, { value: 'high', label: 'Hifi [320kb/s]' }];

  return isOpen ? (
    <Modal title="Settings" close={close}>
      {/* <GroupLabel>UI Settings</GroupLabel> */}
      {/* <Checkbox checked={animations} onChange={toggleAnimations} label="Loading animation" /> */}
      {/* <Checkbox checked={cursorActive} onChange={toggleCursor} label="Fancy cursor" /> */}
      <Radio options={displayOptions} onChange={(val) => handleDisplayChange(val)} value={displayMode} label="Display mode" />
      {/* <Radio options={qualityOptions} onChange={(val) => null} value={'default'} label="Audio quality" /> */}
    </Modal>
  ) : null;
};

SettingsModal.propTypes = {

};

export default SettingsModal;
