import styled, { css } from 'styled-components';
import { mediaQuery, textSizes, spacing } from '../../../assets/theme';

const stylesDefaults = {
  p: {
    lg: textSizes.md,
    sm: textSizes.lg,
  },
  h1: {
    lg: textSizes.hg,
    sm: textSizes.xxl,
  },
  h2: {
    lg: textSizes.xxl,
    sm: textSizes.xl,
  },
  h3: {
    lg: textSizes.lg,
    sm: textSizes.md,
  },
};

export const TextBase = styled.span`
  ${({
    italic,
    $size,
    weight,
    colour,
    align,
    height,
    opacity = 1,
    $fontFamily: fontFamily,
    as: tag,
    outline,
    selectable,
    underline,
  }) => css`
    font-family: ${fontFamily};
    font-weight: ${weight};
    text-align: ${align};
    color: ${outline ? 'transparent' : colour};
    line-height: ${height};
    font-style: ${italic ? 'italic' : 'normal'};
    opacity: ${opacity};
    user-select: ${selectable ? 'inherit' : 'none'};
    text-decoration: ${underline ? 'underline' : 'none'};
    font-feature-settings: 'salt';
    ${tag === 'li' &&
      css`
        list-style: square;
        margin-left: ${spacing[2]}px;
      `};

    ${outline &&
      css`
        text-stroke: 1px ${colour};
        -webkit-text-stroke: 1px ${colour};
      `};

    ${mediaQuery.lessThan('md')`
      font-size: ${getFontSize($size, tag, false)};
    `};

    ${mediaQuery.greaterThan('md')`
      font-size: ${getFontSize($size, tag, true)};
    `};

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }
  `};
`;

const getFontSize = (size, tag, isDesktop) => {
  if (size === 'inherit') return size;
  if (size) return `${size}px`;
  if (stylesDefaults[tag] && isDesktop) return `${stylesDefaults[tag].lg}px`;
  if (stylesDefaults[tag]) return `${stylesDefaults[tag].sm}px`;
  return 'inherit';
};
