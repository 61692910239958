import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const windowGlobal = typeof window !== 'undefined' && window;

export const usePlayerStore = create(set => ({
  sound: null,
  isPlaying: false,
  isLoading: false,
  isLive: false,
  nowPlaying: '',
  listenerCount: 0,

  // Methods
  setIsPlaying: isPlaying => set({ isPlaying }),
  setIsLive: isLive => set({ isLive }),
  setNowPlaying: nowPlaying => set({ nowPlaying }),
  setIsLoading: isLoading => set({ isLoading }),
  setSound: sound => set({ sound }),
  setlistenerCount: listenerCount => set({ listenerCount }),
}));

export const useLivestreamStore = create(set => ({
  isPlaying: false,
  isLoading: false,
  isLive: false,
  video: null,

  // Methods
  setIsPlaying: isPlaying => set({ isPlaying }),
  setIsLoading: isLoading => set({ isLoading }),
  setIsLive: isLive => set({ isLive }),
  setVideo: video => set({ video }),
}));

export const usePlaybackStore = create(set => ({
  slug: '',
  isLoading: false,

  // Methods
  setSlug: slug => set({ slug }),
  setIsLoading: isLoading => set({ isLoading }),
}));

export const useUiStore = create(set => ({
  loaderFinished: false,
  chatOpen: false,
  theme: 'light',
  path: null,
  settingsModalOpen: false,

  // Methods
  setLoaderFinished: () => set({ loaderFinished: true }),
  openSettingsModal: () => set({ settingsModalOpen: true }),
  closeSettingsModal: () => set({ settingsModalOpen: false }),
  setChatOpen: chatOpen => set({ chatOpen }),
  setTheme: theme => set({ theme }),
  setPath: path => set({ path }),
}));

// export const  = create(set => ({
//   currentLang: ['en'].includes(
//     (windowGlobal && windowGlobal?.navigator?.language.substr(0, 2)?.toLowerCase()) || '',
//   )
//     ? 'en'
//     : 'fr',
//   // Methods
//   setCurrentLang: currentLang => set({ currentLang }),
// }));

export const useLangStore = create(persist(
  (set, get) => ({
    currentLang: ['en'].includes(
          (windowGlobal && windowGlobal?.navigator?.language.substr(0, 2)?.toLowerCase()) || '',
        )
          ? 'en'
          : 'fr',

    setCurrentLang: currentLang => set({ currentLang }),
  }),
  {
    name: 'lang', // name of the item in the storage (must be unique)
  },
),
);

// export const [useUserStore] = create(set => ({
//   // Methods
// }));

export const useMetaStore = create(set => ({
  metaImg: null,

  // Methods

  setMetaImg: metaImg => set({ metaImg }),
}));

export const useDeviceStore = create(set => ({
  windowWidth: null,
  windowHeight: null,
  isMobile: null,
  // Methods

  setWindowWidth: windowWidth => set({ windowWidth }),
  setWindowHeight: windowHeight => set({ windowHeight }),
  setIsMobile: isMobile => set({ isMobile }),
}));

export const useUserSettingsStore = create(persist(
  (set, get) => ({
    animations: true,
    displayMode: 'auto',
    animatedCursor: true,

    toggleAnimations: () => set({ animations: !get().animations }),
    toggleAnimatedCursor: () => set({ animatedCursor: !get().animatedCursor }),
    setDisplayMode: displayMode => set({ displayMode }),
  }),
  {
    name: 'user-settings', // name of the item in the storage (must be unique)
  },
),
);
