import styled from 'styled-components';
import { Row } from '../../Layout/Flex';
import { Label, A } from '../Text';

export const Box = styled.div`
  height: 16px;
  width: 16px;
  border: 2px solid ${({ theme }) => theme.foreground};
  background-color: ${({ theme, checked }) => checked ? theme.foreground : theme.background};
  display: block;
`;

export const CheckboxLabel = styled(Label)`
  margin-left: 16px;
  cursor: none;
  display: inline-block;
`;

export const StyledRow = styled(Row)`
  ${props => props.$disabled && `
    opacity: 0.5;
  `}
  display: flex;
  align-items: center;
  cursor: none;
  margin-bottom: 8px;
  width: max-content;
  max-width: 85vw;

  a {
    margin-left: 4px
  }
`;
