import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import useMightyMouse from 'react-hook-mighty-mouse';

import Cursor from '../../atoms/Cursor';
import { CursorWrapper } from './styles';
import { useUiStore } from '../../../store';
import { screenSizes } from '../../../assets/theme';

const CursorContainer = ({ children, active = true, ...rest }) => {
  const loaderFinished = useUiStore(store => store.loaderFinished);
  const [isClicking, setIsClicking] = useState(false);
  const screenSizeMd = Number(screenSizes.md.match(/\d+/)[0]);

  const { width, height } = useWindowSize();
  const { position } = useMightyMouse();

  const isActive = active && loaderFinished && width > screenSizeMd;

  return (
    <CursorWrapper
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      active={isActive}
      {...rest}
    >
      {isActive && (
        <Cursor position={position} isClicking={isClicking} windowDimensions={{ width, height }} />
      )}
      {children}
    </CursorWrapper>
  );
};

CursorContainer.propTypes = {};

export default CursorContainer;
