import styled, { css } from 'styled-components';
import { colours } from '../../../assets/theme';
import { Span } from '../../atoms/Text';

export const Container = styled.div`
  width: ${props => props.inline ? 'auto' : '100vw'};
  height: ${props => props.inline ? 'auto' : '100vh'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme?.background};
  z-index: 10000000;
`;

export const LineBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const MovedSpan = styled(Span).attrs(props => ({
  style: {
    transform: `translate(
        ${props.coords.length && props.coords[0]}%,
        ${props.coords.length && props.coords[1]}%
      )`,
  },
}))`
  display: inline-block;
  position: relative;
  /* transition: all 0.1s ease-in-out; */
  user-select: none;

  ${props =>
    !props.isRunning &&
    css`
      transition-delay: ${Math.random() / 2}s;
      opacity: 0.00001;
    `}
`;
