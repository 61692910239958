import styled from 'styled-components';
import { Row } from '../../Layout/Flex';
import { Label, A } from '../Text';

export const Radio = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.foreground};
  background-color: ${({ theme, checked }) => checked ? theme.foreground : theme.background};
  display: block;
`;

export const GroupLabel = styled(Label)`
  font-weight: 700;
  margin: 12px 0 8px 0;
  display: block;
`;

export const OptionLabel = styled(Label)`
  margin: 0 24px 0 8px;
  cursor: none;
  display: inline-block;
`;

export const StyledRow = styled(Row)`
  ${props => props.$disabled && `
    opacity: 0.5;
  `}
  display: flex;
  align-items: center;
  cursor: none;
  margin-bottom: 8px;
  /* width: max-content; */
  flex-wrap: wrap;
`;
