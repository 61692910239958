import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import axios from 'axios';

import { useLivestreamStore, useUiStore, useUserSettingsStore } from './src/store';
import AppWrapper from './src/components/organisms/AppWrapper';

import { useKeyboardShortcut } from './src/hooks/keyboardHooks';
import { useInterval, useTimeout } from './src/hooks/TimerHooks';

import config from './config';
import { seconds } from './src/helpers/time';

const { peertubeRoot, livestreamId } = config;

const LivestreamWrapper = ({ children }) => {
  const [setVideo, setIsLive] = useLivestreamStore(store => [store.setVideo, store.setIsLive]);

  useEffect(() => {
    getLivestream();
  }, []);

  useInterval(() => {
    getLivestream();
  }, seconds(60));

  const getLivestream = async () => {
    const {
      data: {
        account,
        category,
        channel,
        embedPath,
        language,
        state,
        previewPath,
        views,
        duration,
        name,
        description,
      },
    } = await axios.get(`${peertubeRoot}/api/v1/videos/${livestreamId}`);

    const item = {
      title: name,
      account,
      category,
      channel,
      looseAntennaUrl: description,
      embedPath,
      language,
      state,
      previewPath,
      views,
      duration,
      // videoUrl: url.getFirstValue(),
    };

    setIsLive(state.id === 1);
    setVideo(item);
  };

  return children;
};
const ThemeWrapper = ({ children }) => {
  const setTheme = useUiStore(store => store.setTheme);
  const [countdownActive, setCountdownActive] = useState(false);
  const theme = useUiStore(store => store.theme);
  const displayMode = useUserSettingsStore(store => store.displayMode);
  const setDisplayMode = useUserSettingsStore(store => store.setDisplayMode);

  const isDark = useMedia('(prefers-color-scheme: dark)');

  useKeyboardShortcut(['Shift', 'H', 'I'], () => {
    if (!countdownActive) {
      setTheme(theme === 'light' ? 'dark' : 'light');
      setCountdownActive(true);
    }
  });

  useKeyboardShortcut(['Shift', 'D', 'M'], () => {
    if (!countdownActive) {
      setDisplayMode('dark')
      setTheme('dark');
      setCountdownActive(true);
    }
  });

  useKeyboardShortcut(['Shift', 'L', 'M'], () => {
    if (!countdownActive) {
      setDisplayMode('light')
      setTheme('light');
      setCountdownActive(true);
    }
  });

  useTimeout(
    () => {
      setCountdownActive(false);
    },
    countdownActive ? 1000 : null,
  );

  useEffect(() => {
    if(displayMode === 'auto') {
      setTheme(isDark ? 'dark' : 'light');
    } else {
      setTheme(displayMode);
    }
  }, [isDark, displayMode]);

  return children;
};

export const wrapPageElement = ({ element, props }) => {
  const isNowPlayingPage = props.location.pathname.includes('nowplaying');

  return isNowPlayingPage ? (
    <ThemeWrapper>
      {element}
    </ThemeWrapper>
  ) : (
    <LivestreamWrapper>
      <ThemeWrapper>
        <AppWrapper {...props}>{element}</AppWrapper>
      </ThemeWrapper>
    </LivestreamWrapper>
  );
};
