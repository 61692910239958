import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes, { oneOfType, shape, string } from 'prop-types';

import { P } from '../../atoms/Text';
import PlaybackPlayer from '../../atoms/PlayBackPlayer';

import PlayBar from '../../molecules/Playbar';
import Chat from '../../atoms/Chat';
import SettingsSvg from '../../icons/SettingsCog';
import Loader from '../../molecules/Loader';
import CursorContainer from '../CursorContainer';
import PageWrapper from '../../Layout/PageContainer';
import {
  useUiStore,
  useLangStore,
  usePlaybackStore,
  useLivestreamStore,
  useDeviceStore,
  useUserSettingsStore,
} from '../../../store';
import { useTranslation } from '../../../hooks/TranslationHooks';
import { Main, SideLink, LinkBox, LinkBoxRow } from './styles';
import { textSizes, lightTheme, darkTheme, screenSizes } from '../../../assets/theme';

import '../../../assets/styles/font-face.css';
import '../../../assets/styles/layout.css';
import ListenerIndicator from '../../molecules/ListenerIndicator';
import { graphql, useStaticQuery } from 'gatsby';
import format from 'date-fns/format';
import { useWindowSize } from 'react-use';
import SettingsModal from '../../molecules/SettingsModal';

const AppWrapper = ({ children }) => {
  const { loaderFinished } = useUiStore(store => store);
  const { chatOpen, setChatOpen, path } = useUiStore(store => ({
    chatOpen: store.chatOpen,
    setChatOpen: store.setChatOpen,
    path: store.path,
  }));
  const currentLang = useLangStore(store => store.currentLang);
  const theme = useUiStore(store => store.theme);
  const translations = useTranslation();
  const playbackSlug = usePlaybackStore(store => store.slug);
  const livestreamIsLive = useLivestreamStore(store => store.isLive);
  const setWindowWidth = useDeviceStore(store => store.setWindowWidth);
  const setWindowHeight = useDeviceStore(store => store.setWindowHeight);
  const setIsMobile = useDeviceStore(store => store.setIsMobile);
  const { width: deviceWidth, height: deviceHeight } = useWindowSize();
  const { openSettingsModal } = useUiStore(store => store);
  const cursorActive = useUserSettingsStore(store => store.animatedCursor);
  const loaderAnimation = useUserSettingsStore(store => store.animations);

  

  useEffect(() => {
    const isMobile = deviceWidth < Number(screenSizes.md.match(/\d+/)[0]);
    setWindowWidth(deviceWidth);
    setWindowHeight(deviceHeight);
    setIsMobile(isMobile);
  }, [deviceWidth, deviceHeight]);

  const { aboutPage, newsPage } = useStaticQuery(graphql`
    query pageTitlesQuery {
      aboutPage: strapiAboutPage {
        page_meta {
          page_title_fr
          page_title_en
        }
      }
      newsPage: strapiNewsPage {
        page_meta {
          page_title_fr
          page_title_en
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <CursorContainer active={cursorActive}>
        <PageWrapper>
          {loaderFinished ? (
            <>
              <SideLink to="/schedule" verticalPosition="top" horizontalPosition="right">
                <P size={textSizes.lg} weight="600" selectable={false}>
                  {translations.schedule[currentLang]}
                </P>
              </SideLink>
              <SideLink to="/archive" verticalPosition="bottom" horizontalPosition="right">
                <P size={textSizes.lg} weight="600" selectable={false}>
                  {translations.archive[currentLang]}
                </P>
              </SideLink>
              <SideLink to="/about" verticalPosition="top" horizontalPosition="left">
                <P size={textSizes.lg} weight="600" selectable={false}>
                  {aboutPage.page_meta[`page_title_${currentLang}`].toLowerCase()}
                </P>
              </SideLink>
              <SideLink to="/posts" verticalPosition="bottom" horizontalPosition="left">
                <P size={textSizes.lg} weight="600" selectable={false}>
                  {newsPage.page_meta[`page_title_${currentLang}`].toLowerCase()}
                </P>
              </SideLink>
            </>
          ) : (
            <Loader skip={!loaderAnimation}/>
          )}

          <Main>{children}</Main>
          {loaderFinished && (
            <>
              {chatOpen ? (
                <Chat />
              ) : (
                <LinkBoxRow>
                <LinkBox
                padded
                  onClick={() => setChatOpen(!chatOpen)}
                  playbackPlayerOpen={playbackSlug !== ''}
                  data-hoverable
                >
                  <P size={textSizes.lg} weight="600" selectable={false}>
                    Chat
                  </P>
                </LinkBox>
                <LinkBox
                  onClick={openSettingsModal}
                  playbackPlayerOpen={playbackSlug !== ''}
                  data-hoverable
                >
                  <SettingsSvg size={32}/>
                </LinkBox>
                </LinkBoxRow>
              )}
              {/* <ListenerIndicator /> */}
              {!livestreamIsLive ? <PlayBar /> : null}
              {playbackSlug !== '' && <PlaybackPlayer slug={playbackSlug} />}
            </>
          )}
        </PageWrapper>
      </CursorContainer>
    </ThemeProvider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppWrapper;
