import React from 'react';
import { camelise, capitaliseFirstLetter } from '../../../helpers/string';

const titleCases = ['capitaliseFirstLetter', 'capitaliseFirst', 'title'];
const camelCases = ['camel', 'camelCase', 'camelise', 'camelize'];
const upperCases = ['upper', 'upperCase', 'uppercase'];
const lowerCases = ['lower', 'lowerCase', 'lowercase'];
const noCase = ['none', null, undefined, ''];
export const allCases = [...titleCases, ...camelCases, ...upperCases, ...lowerCases, ...noCase];

export const caseText = (text, letterCase) => {
  if (upperCases.includes(letterCase)) {
    return text.toUpperCase();
  }
  if (lowerCases.includes(letterCase)) {
    return text.toLowerCase();
  }
  if (camelCases.includes(letterCase)) {
    return camelise(text);
  }
  if (titleCases.includes(letterCase)) {
    return capitaliseFirstLetter(text);
  }
  return text;
};

export const splitChildrenToTag = (string, splitBy = '', tagName = 'span') => {
  const Tag = tagName;
  return string.split(splitBy).map(letter => <Tag>{letter}</Tag>);
};
