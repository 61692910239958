import React, { useState, useRef, useEffect } from 'react';
import { withTheme } from 'styled-components';

import { H1 } from '../../atoms/Text';
import { Container, MovedSpan, LineBreak } from './styles';

import { useInterval } from '../../../hooks/TimerHooks';
import { useDeviceStore, useUiStore } from '../../../store';
import { string } from 'prop-types';
import { colours, screenSizes } from '../../../assets/theme';
import config from '../../../../config';
import { format } from 'date-fns';
import { useWindowSize } from 'react-use';

const getFillColour = (isFilled, theme) => {
  if (isFilled) return theme?.foreground || colours.black;
  return theme?.highlight;
};

const windowGlobal = typeof window !== 'undefined' && window;

const Loader = withTheme(({ theme, title = config.name, loading, inline, skip }) => {
  let timer;
  const setLoaderDone = useUiStore(store => store.setLoaderFinished);

  const [titleVisible, setTitleVisible] = useState(true);
  const [isRunning, setIsRunning] = useState(true);
  const offsets = [15, 30, 35];
  const displacement = useRef(offsets[Math.floor(Math.random() * offsets.length)]);

  const delay = 130;
  const counter = useRef(1);

  useEffect(() => {
    const pageAccessedByReload = windowGlobal && (
      (windowGlobal.performance.navigation && windowGlobal.performance.navigation.type === 1) ||
        windowGlobal.performance
          .getEntriesByType('navigation')
          .map((nav) => nav.type)
          .includes('reload')
    );
    
    if(skip || pageAccessedByReload) {
      setLoaderDone()
    }
    return () => {
      if (windowGlobal) {
        windowGlobal.clearTimeout(timer);
      }
    };
  }, [skip]);

  useInterval(
    () => {
      if (counter.current > 14) {
        unloadLoader();
        setIsRunning(false);
        localStorage.setItem('hasVisited', true);
      }
      setTitleVisible(!titleVisible);
      counter.current += 1;
    },
    loading || isRunning ? delay : null,
  );

  const getRandNegativePositive = (max = displacement.current) => {
    let num = Math.floor(Math.random() * max) + 1;
    num *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
    return num;
  };

  const unloadLoader = () => {
    window.setTimeout(() => {
      setLoaderDone();
    }, 1000);
  };

  return (
    <Container inline={inline}>
      {setTitleVisible && (
        <H1 align="center">
          {title.split('').map((letter, index) => {
            const isFilled = Math.random() >= 0.3;
            return letter === ' ' ? (
              <LineBreak key={`${letter}-${index}`} />
            ) : (
              <MovedSpan
                coords={[getRandNegativePositive(), getRandNegativePositive()]}
                isRunning={loading ?? isRunning}
                index={index}
                key={`${letter}-${index}`}
                weight="700"
                outline={isFilled}
                colour={getFillColour(isFilled, theme)}
              >
                {letter}
              </MovedSpan>
            );
          })}
        </H1>
      )}
    </Container>
  );
});

Loader.propTypes = {
  title: string,
};

export default Loader;
