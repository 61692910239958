import React, { useState } from 'react';

import { withTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { string, bool } from 'prop-types';
import CrossSvg from '../../icons/Cross';

import { PlayBackPlayerBar, PlaybackBarWrapper, Xwrap, LoaderBox } from './styles';
import { usePlaybackStore, useUiStore } from '../../../store';
import ParticleLoader from '../ParticleLoader';

const AnimatedWrapper = animated(PlaybackBarWrapper);
const fast = { mass: 1, tension: 1200, friction: 40 };

const PlayBackPlayer = withTheme(({ slug, theme, ...rest }) => {
  const setPlaybackSlug = usePlaybackStore(store => store.setSlug);
  const setIsLoading = usePlaybackStore(store => store.setIsLoading);
  const userTheme = useUiStore(store => store.theme);

  const url = constructUrl(slug);

  const barPosition = useSpring({ bottom: 0, config: fast });

  const close = () => setPlaybackSlug('');

  const src = `https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&${userTheme}=1&autoplay=1&feed=${url}`;

  return slug ? (
    <AnimatedWrapper style={barPosition} data-no-cursor>
      <PlayBackPlayerBar
        width="100%"
        height="60"
        src={src}
        frameborder="0"
        {...rest}
        onLoad={() => setIsLoading(false)}
      />
      <Xwrap onClick={close}>
        <CrossSvg colour={theme?.foreground} />
      </Xwrap>
    </AnimatedWrapper>
  ) : null;
});

const constructUrl = slug => {
  let url;
  url = slug.charAt(slug.length) === '/' ? slug : `${slug}/`;
  url = slug.charAt(0) === '/' ? url : `/${url}`;

  return encodeURIComponent(`/looseantenna${url}`);
};

PlayBackPlayer.propTypes = {
  slug: string,
  looseantenna: bool,
};

export default PlayBackPlayer;
