import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import Draggable from 'react-draggable';

import { P } from '../Text';
import CrossSvg from '../../icons/Cross';

import { screenSizes } from '../../../assets/theme';
import { useUiStore, usePlaybackStore, useLivestreamStore, useDeviceStore } from '../../../store';

import { ChatPlaceholder, DiscordLink, RightBox, TopBar } from './styles';

import getChatTheme, { chatConf } from './config';
import config from '../../../../config';

const Chat = ({ ...rest }) => {
  const userTheme = useUiStore(({ theme }) => theme);
  const playbackOpen = usePlaybackStore(({ slug }) => slug) !== '';
  const placeholder = useRef(null);
  const setChatOpen = useUiStore(store => store.setChatOpen);
  const { width: deviceWidth, height: deviceHeight } = useWindowSize();
  const isMobile = useDeviceStore(store => store.isMobile);
  const chatHeight = isMobile ? deviceHeight - 52 : 500;
  const chatWidth = isMobile ? deviceWidth : 350;
  const chatConfig = getChatTheme(userTheme);
  const [dragging, setDragging] = useState(false);
  const livestreamIsLive = useLivestreamStore(store => store.isLive);

  const unmount = () => {
    setChatOpen(false);
  };

  return (
    <Draggable
      onStart={() => {
        setDragging(true);
      }}
      onStop={() => {
        setDragging(false);
      }}
      disabled={isMobile}
    >
      <ChatPlaceholder
        ref={placeholder}
        data-no-cursor
        playbackPlayerOpen={playbackOpen}
        livestreamIsLive={livestreamIsLive}
        {...rest}
        handle=".handle"
      >
        <TopBar className=".handle" dragging={dragging}>
          <P weight="600" size={20}>
            Chat
          </P>
          <DiscordLink href={config.discordInvite} target="_blank" rel="noopener noreferrer">
            → Open in the discord app
          </DiscordLink>
          <RightBox onClick={unmount}>
            <CrossSvg colour={`#${chatConfig.titleColour}`} />
          </RightBox>
        </TopBar>
        <iframe
          src={`https://e.widgetbot.io/channels/${chatConf.guildId}/${chatConf.channelId}`}
          height={chatHeight}
          width={chatWidth}
          frameBorder="0"
          title="discord-chat"
        />
      </ChatPlaceholder>
    </Draggable>
  );
};

export default Chat;
