import React, { useState } from 'react';
import { bool, number } from 'prop-types';
import { Particle, Container } from './styles';
import { useInterval } from '../../../hooks/TimerHooks';

const ParticleLoader = ({ active = true, particles, size = 40, particleSize = 1, rate = 100, colour, ...rest }) => {
  const [positionsArray, updatePositionsArray] = useState(
    Array(particles).fill({
      top: 0,
      left: 0,
    }),
  );

  useInterval(
    () => {
      setNewPositions();
    },
    active ? rate : null,
  );

  const setNewPositions = () => {
    updatePositionsArray(
      positionsArray.map(() => ({
        top: `${Math.floor(Math.random() * size)}px`,
        left: `${Math.floor(Math.random() * size)}px`,
      })),
    );
  };

  return (
    <Container size={size} {...rest}>
      {positionsArray.map((pos, i) => {
        return (
          <Particle
            colour={colour}
            size={particleSize}
            key={`${pos.left}-${i}`}
            style={{
              ...pos,
            }}
          />
        );
      })}
    </Container>
  );
};

ParticleLoader.propTypes = {
  active: bool,
  particles: number,
};

export default ParticleLoader;
