import { format, isToday, isYesterday, isTomorrow } from 'date-fns';

export const seconds = secondsIn => {
  return secondsIn * 1000;
};

export const minutes = minutesIn => {
  return seconds(minutesIn) * 60;
};

export const hours = hoursIn => {
  return minutes(hoursIn) * 60;
};
