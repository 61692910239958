import styled from 'styled-components';

export const CursorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12541;
  cursor: ${props => (props.active ? 'none' : 'unset')};
`;
