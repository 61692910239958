import styled, { css } from 'styled-components';

export const LinkWrapper = styled.span`
  opacity: ${props => (props.disabled ? '0.5' : 1)};
  a {
    ${props =>
      props.colour &&
      css`
        color: ${props.colour};
      `}
  }
`;
