import styled from 'styled-components';
import { colours } from '../../../assets/theme';

const PageWrapper = styled.div`
  background-color: ${props => props.theme?.background};
  transition: background-color 0.3s ease-in-out;
  width: 100vw;
  height: 100vh;
`;

export default PageWrapper;
