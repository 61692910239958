import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { string, bool } from 'prop-types';
import { LinkWrapper } from './styles';

const Link = ({ children, to, href, hoverable = true, disabled, colour, newtab, ...rest }) => {
  if (to) {
    return (
      <LinkWrapper disabled={disabled} {...rest} data-hoverable={!disabled && hoverable} colour={colour}>
        <GatsbyLink to={to}>{children}</GatsbyLink>
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper disabled={disabled} colour={colour} data-hoverable={!disabled && hoverable} {...rest}>
      <a
        href={href}
        data-hoverable={!disabled && hoverable}
        target={newtab ? '_blank' : '_self'}
        rel={newtab ? 'noopener noreferrer' : ''}
      >
        {children}
      </a>
    </LinkWrapper>
  );
};

Link.propTypes = {
  to: string,
  href: string,
  hoverable: bool,
};

export default Link;
