import React, { useEffect, useRef, useState } from 'react';
import { useTrail, useSpring, animated, interpolate } from 'react-spring';

import { CursorMain, CursorParticle, RadialPointer, SmallImg, SmallVideo } from './styles';

import { getRand } from '../../../helpers/util';
import { useInterval } from '../../../hooks/TimerHooks';
import { usePlayerStore } from '../../../store';

import { shape, number } from 'prop-types';

const Particle = animated(CursorParticle);
const Pointer = animated(CursorMain);
const AnimatedRadialPointer = animated(RadialPointer);

const fast = { mass: 1, tension: 1200, friction: 40 };
// const slow = { mass: 1, tension: 500, friction: 34 };
const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;

const Cursor = ({ position, isClicking, windowDimensions }) => {
  const lastPos = useRef([0, 0]);
  const [isHovering, setIsHovering] = useState(false);
  const [hoverImg, setHoverImg] = useState('');
  const [cursorHidden, setCursorHidden] = useState(false);
  const hoverProps = useSpring({ scale: isClicking ? 0.5 : 1, config: fast });
  const isLoading = usePlayerStore(props => props.isLoading);
  const [refreshKey, setKey] = useState(getRand(100));
  const [imagePosition, setImagePosition] = useState({ vertical: 'bottom', horizontal: 'right' });

  const [trail, set] = useTrail(8, () => ({
    xy: lastPos.current,
    opacity: position.client.x ? 1 : 0,
    config: fast,
  }));

  useEffect(() => {
    set({ xy: position.client.x ? [position.client.x, position.client.y] : lastPos.current });
    if (position.client.x) {
      lastPos.current = [position.client.x, position.client.y];
    }
    lookForHoverables([position.client.x, position.client.y]);

    setImagePosition(getImagePosition(position.client.x, position.client.y));
  }, [position.client.x, position.client.y]);

  useInterval(
    () => {
      setKey(getRand(100));
    },
    isLoading ? 100 : null,
  );

  const lookForHoverables = coords => {
    let isHovered = false;
    let hoverImage = '';
    let noCursor = false;
    document.elementsFromPoint(coords[0], coords[1]).forEach(el => {
      if (el.dataset?.hoverable === 'true') {
        isHovered = true;
      }
      if (el.dataset?.hoverImg) {
        hoverImage = el.dataset?.hoverImg;
      }
      if (el.dataset?.noCursor === 'true') {
        noCursor = true;
      }
    });

    setIsHovering(isHovered);
    setHoverImg(hoverImage);
    setCursorHidden(noCursor);
  };

  const getImagePosition = (x, y) => {
    const vertical = y > windowDimensions.height - 250 ? 'top' : 'bottom';
    // const horizontal = x > windowDimensions.width / 2 ? 'left' : 'right';
    const horizontal = 'right';

    return { vertical, horizontal };
  };

  return (
    <>
      {/* {
        <>
          <Pointer
            isHovering={isHovering}
            isActive={!position.client.x}
            style={{
              transform: interpolate(
                [trail[0].xy.interpolate(trans), hoverProps.scale.interpolate(v => `scale(${v})`)],
                (scale, translate) => `${scale} ${translate}`,
              ),
            }}
          />
          <Pointer
            isHovering={isHovering}
            isActive={!position.client.x}
            isHorizontal
            style={{
              transform: interpolate(
                [trail[0].xy.interpolate(trans), hoverProps.scale.interpolate(v => `scale(${v})`)],
                (scale, translate) => `${scale} ${translate}`,
              ),
            }}
          />
        </>
      } */}

      <AnimatedRadialPointer
        isHovering={isHovering}
        style={{
          transform: interpolate(
            [trail[0].xy.interpolate(trans), hoverProps.scale.interpolate(v => `scale(${v})`)],
            (scale, translate) => `${scale} ${translate}`,
          ),
          opacity: position.client.x && !cursorHidden ? 1 : 0,
        }}
      >
        {hoverImg && (
          <>
            {hoverImg.split('.').pop() === 'mp4' ? (
              <SmallVideo playsInline muted loop autoPlay onCanPlay="this.muted=true">
                <source src={hoverImg} type="video/mp4" />
              </SmallVideo>
            ) : (
              <SmallImg pos={imagePosition} src={hoverImg} alt="" />
            )}
          </>
        )}
      </AnimatedRadialPointer>

      {isLoading &&
        trail.map((props, index) => (
          <Particle
            key={`${index}-${refreshKey}`}
            style={{
              transform: props.xy.interpolate(trans),
              top: getRand(25),
              left: getRand(25),
            }}
          />
        ))}
    </>
  );
};

Cursor.propTypes = {
  position: shape({
    client: shape({
      x: number,
      y: number,
    }),
    screen: shape({
      x: number,
      y: number,
    }),
    page: shape({
      x: number,
      y: number,
    }),
  }),
};

export default Cursor;
