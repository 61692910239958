import styled, { css, keyframes } from 'styled-components';
import { colours, mediaQuery } from '../../../assets/theme';

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Bar = styled.div`
  padding: 0 14px;
  height: 50px;
  display: grid;
  grid-template-columns: 33px minmax(auto, min-content) auto;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: ${props => props.theme?.background};
  transition: background-color 0.3s ease-in-out;
  border-top: 1px solid ${props => props.theme?.foreground};
  z-index: 12540;

  grid-column-gap: 10px;
  
  ${IconContainer} {
    grid-row: span 2;
    grid-column: 1;
  }
  
  p {
    grid-row: span 2;
  }
  
  span.playbar--playback-type {
    grid-row: span 2;
    white-space: nowrap;
  }
  
  ${mediaQuery.lessThan('sm')`
    grid-column-gap: 5px;

    p {
      display: contents
    }
    
    span.playbar--play-status, span.playbar--now-playing {
      grid-column-start: 2;
      grid-column-end: 4;
    }
    
    span.playbar--play-status {
      grid-row: 1;
    }

    span.playbar--now-playing {
      grid-row: 2;
      max-width: 100%;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }
    
    span.playbar--playback-type {
      grid-row: 1;
      grid-column: 3;
    }
  `};

  & > div:first-of-type {
    display: contents;
  }
  

  svg {
    stroke-width: 1;
    stroke: ${props => props.theme?.foreground};
  }
`;

export const StandaloneBar = styled(Bar)`
  width: 100%;
  grid-template-rows: 1fr 1fr;
  background-color: transparent;

  border: 2px solid ${props => props.colour || colours.white};
  border-radius: 150px;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  position: static;
  

  svg {
    stroke-width: 2;
    stroke: ${props => props.colour || colours.white};
  }

  * {
    cursor: pointer;
  }
`;
