import React from 'react';
import { number, string } from 'prop-types';

const Stop = ({ size = '24', colour = 'inherit', ...rest }) => (
  <svg viewBox="0 0 24 24" height={size} width={size} {...rest}>
    <rect width="20" height="20" x="2" y="2" fill={colour} fillRule="evenodd" rx="1" />
  </svg>
);

Stop.propTypes = {
  size: number,
  colour: string,
};

export default Stop;
