import styled from 'styled-components';
import { colours, mediaQuery } from '../../../assets/theme';

const topBarHeight = 50;

const getChatPosition = (playbackPlayerOpen, livestreamIsLive) => {
  if (livestreamIsLive) return 0;
  if (playbackPlayerOpen) return '57px';
  return '48px';
};

export const ChatPlaceholder = styled.div`
  position: fixed;
  right: 29px;
  bottom: ${props => getChatPosition(props.playbackPlayerOpen, props.livestreamIsLive)};
  background-color: ${props => props.theme?.background ?? colours.black};
  z-index: 2;

  iframe {
    border: 1px solid ${props => props.theme?.foreground ?? colours.white};
  }

  ${mediaQuery.lessThan('md')`
    right: 0;
    iframe {
      box-sizing: border-box;
    }
`}
`;

export const RightBox = styled.div`
  border-left: 1px solid ${props => props.theme?.foreground ?? colours.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  cursor: pointer;
  height: ${topBarHeight - 15}px;
  width: ${topBarHeight - 15}px;
`;

export const TopBar = styled.div`
  position: absolute;
  top: 0px;
  background-color: ${props => props.theme?.background ?? colours.black};
  cursor: ${props => (props.dragging ? 'grabbing' : 'grab')};

  height: ${topBarHeight}px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: space-between;
  padding: 4px 4px 4px 12px;
  right: 0;
  border: 1px solid ${props => props.theme?.foreground ?? colours.white};

  p {
    position: relative;
    top: 2px;
  }
`;

export const DiscordLink = styled.a`
  background-color: ${colours.black};
  cursor: pointer;
  position: absolute;
  top: ${topBarHeight - 1}px;
  right: 0;
  color: ${colours.white};
  padding: 4px 6px;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  font-weight: 600;
  font-family: 'SpaceGrotesk';
  user-select: none;

  &:hover {
    background-color: ${colours.red};
  }
`;
