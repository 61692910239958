/* eslint-disable no-unused-expressions */
import { useStaticQuery, graphql } from 'gatsby';

// TODO: when only lang provided return all translations for that language.
export const useTranslation = ({ key, lang } = {}) => {
  const { allStrapiWordTranslations } = useStaticQuery(
    graphql`
      query MyQuery {
        allStrapiWordTranslations {
          edges {
            node {
              text_en
              text_fr
              key
            }
          }
        }
      }
    `,
  );
  const translations = {};

  allStrapiWordTranslations?.edges?.forEach(({ node: { key: nodeKey, text_en: en, text_fr: fr } }) => {
    translations[nodeKey] = { en, fr };
  });

  if (key && lang) return translations[key][lang];
  if (key) return translations[key];
  return translations;
};
