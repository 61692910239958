import React, { useState } from 'react';
import { ModalBody, ModalBox, ModalContainer } from './styles';
import { useDeviceStore } from '../../../store';
import { P } from '../../atoms/Text';
import { RightBox, TopBar } from '../../atoms/Chat/styles';
import CrossSvg from '../../icons/Cross';
import { useTheme } from 'styled-components';
import Draggable from 'react-draggable';

const Modal = ({ title = 'Modal title', children, close }) => {
  const theme = useTheme();
  const isMobile = useDeviceStore(store => store.isMobile);

  const [dragging, setDragging] = useState(false);

  return (

    <ModalContainer>
      <Draggable
        onStart={() => {
          setDragging(true);
        }}
        onStop={() => {
          setDragging(false);
        }}
        disabled={isMobile}
      >
        <ModalBox>
          <TopBar className=".handle" dragging={dragging}>
            <P weight="600" size={20}>
              {title}
            </P>
            <RightBox onClick={close}>
              <CrossSvg colour={theme.foreground} />
            </RightBox>
          </TopBar>
          {/* <Label size={textSizes.lg}>{title}</Label> */}
          <ModalBody>
            {children}
          </ModalBody>
        </ModalBox>
      </Draggable>
    </ModalContainer>
  );
};

Modal.propTypes = {

};

export default Modal;
