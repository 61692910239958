import styled from 'styled-components';
import { colours, mediaQuery } from '../../../assets/theme';
import ParticleLoader from '../ParticleLoader';

export const PlayBackPlayerBar = styled.iframe`
  border-right: 1px solid ${props => props.theme?.foreground};
  border-left: 1px solid ${props => props.theme?.foreground};
  ${mediaQuery.lessThan('lg')`
  border-width: 1px;
`}
`;

export const PlaybackBarWrapper = styled.div`
  position: fixed;
  bottom: -60px;
  right: 0;
  z-index: 13221321;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 33%;
  ${mediaQuery.lessThan('lg')`
    width: 100%;
  `}
`;

export const Xwrap = styled.button`
  height: 60px;
  width: 60px;
  background-color: ${props => props.theme?.background ?? colours.black};
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
`;

export const LoaderBox = styled.div`
  position: absolute;
`;
