import { generateMedia } from 'styled-media-query';
import { transparentize } from 'polished';

export const screenSizes = {
  xs: '321px',
  sm: '450px',
  md: '768px',
  lg: '1200px',
  xl: '1441px',
};

export const mediaQuery = generateMedia({
  ...screenSizes,
});

export const colours = {
  white: '#fff',
  black: '#212121',
  blue: '#0066ff',
  femPurp: '#73298c',
  green: '#63C4BB',
  yellow: '#ffeb8d',

  red: '#FF5642',
};

colours.grey = transparentize(0.7, colours.black);

export const spacingBase = 4;
export const spacing = [2, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512];

export const textSizes = {
  xs: 11,
  sm: 14,
  md: 16,
  lg: 21,
  xl: 32,
  xxl: 92,
  hg: 200,
};

export const fontFamilies = ['SpaceGrotesk'];

export default {
  colours,
  textSizes,
  spacingBase,
  spacing,
  fontFamilies,
  mediaQuery,
};

export const lightTheme = {
  background: colours.white,
  foreground: colours.black,
  highlight: colours.blue,
  danger: colours.red,
};

export const darkTheme = {
  background: colours.black,
  foreground: colours.white,
  highlight: colours.blue,
  danger: colours.red,
};

export const gfTheme = {
  background: colours.femPurp,
  foreground: colours.white,
  highlight: colours.yellow,
  danger: colours.red,
};
