import { colours } from '../../../assets/theme';

export const chatConf = {
  guildId: '709688374470115388',
  channelId: '781578895840509984',
};

const black = colours.black.substr(1);
const white = colours.white.substr(1);

const defaults = {
  backgroundOpacity: 100,
  borderOpacity: 100,
  fontSize: 12.65,
  showFontStyler: 0,
  showClose: 0,
  showUrl: 0,
  borderRadius: 0,
  userIconSize: 0.75,
};

export default theme => ({
  backgroundColour: theme === 'dark' ? black : white,
  titleColour: theme === 'dark' ? white : black,
  titleMessageColour: theme === 'dark' ? white : black,
  messageBackgroundColour: theme === 'dark' ? black : white,
  messageTextColour: theme === 'dark' ? white : black,
  dateColour: '7d7d7d',
  messageBorderColour: theme === 'dark' ? black : white,
  buttonBackground: theme === 'dark' ? black : white,
  buttonText: theme === 'dark' ? white : black,
  borderColour: theme === 'dark' ? black : white,
  scrollBarColour: theme === 'dark' ? white : black,
  ...defaults,
});
