/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from 'react';

import styled, { keyframes, css } from 'styled-components';
import { colours } from '../../../assets/theme';

export const CursorMain = styled.div`
  height: ${({ isHorizontal }) => (isHorizontal ? 1 : 10)}px;
  width: ${({ isHorizontal }) => (isHorizontal ? 10 : 1)}px;
  background-color: ${({ isHovering, theme }) => (isHovering ? theme?.highlight : theme?.foreground)};
  opacity: ${({ isHovering, isActive }) => (isActive ? 0 : isHovering ? 0.5 : 1)};
  position: absolute;
  transition: background-color 0.3s ease-in-out;
  z-index: 5;
  mix-blend-mode: exclusion;
`;

export const CursorParticle = styled.div`
  height: 1px;
  width: 1px;
  background-color: ${props => props.theme?.foreground};
  position: absolute;
  z-index: 4;
`;

const rotate = keyframes`
0% {
  transform:rotate(0deg);
}
 100% { transform:rotate(360deg); }
`;

export const RadialPointerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  transition: opacity 0.3s ease-in-out;
  z-index: 100000;
  user-select: none;
  pointer-events: none;

  & > div {
    ${props =>
    props.isHovering &&
    css`
        transform-origin: 40px 40px;
        animation: 2s ${rotate} linear;
        animation-fill-mode: backwards;
        animation-iteration-count: infinite;
      `};

    div {
      transform-origin: 40px 40px;
    }
    div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 1px;
      left: 40px;
      width: 1px;
      height: 18px;
      border-radius: 20%;
      background: ${props => props.theme?.foreground};
      border: 1px solid ${props => props.theme?.background ?? colours.black};
    }
    div:nth-child(1) {
      transform: rotate(90deg);
    }
    div:nth-child(2) {
      transform: rotate(141deg);
    }
    div:nth-child(3) {
      transform: rotate(193deg);
    }
    div:nth-child(4) {
      transform: rotate(244deg);
    }
    div:nth-child(5) {
      transform: rotate(296deg);
    }
    div:nth-child(6) {
      transform: rotate(347deg);
    }
    div:nth-child(7) {
      transform: rotate(39deg);
    }
  }
`;

export const RadialPointer = ({ children, ...props }) => (
  <RadialPointerWrapper {...props}>
    <div>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    {children}
  </RadialPointerWrapper>
);

export const SmallImg = styled.img`
  max-height: 200px;
  position: absolute;
  top: ${props => (props.pos?.vertical === 'top' ? 'initial' : '80px')};
  bottom: ${props => (props.pos?.vertical === 'top' ? '80px' : 'initial')};
  left: 80px;
`;

export const SmallVideo = styled.video`
  width: auto;
  max-height: 200px;
  height: auto;
  position: absolute;
  top: ${props => (props.pos?.vertical === 'top' ? 'initial' : '80px')};
  bottom: ${props => (props.pos?.vertical === 'top' ? '80px' : 'initial')};
  left: 80px;
`;
