import styled from 'styled-components';
import { colours, mediaQuery } from '../../../assets/theme';

export const ModalContainer = styled.div`
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    pointer-events: none;
    top: 0;
    left: 0;
    
    `;

export const ModalBox = styled.div`
    width: 25%;
    background-color: ${({ theme }) => theme.background};
    position: relative;
    pointer-events: all;
    z-index: 4;

    ${mediaQuery.lessThan('sm')`
    width: 98%;
    `}
`;

export const ModalBody = styled.div`
    padding: 52px 4px 12px 12px;
    border: 1px solid ${({ theme }) => theme.foreground};
    /* background-color: ${({ theme }) => theme.background}; */
`;
