import React from 'react';
import { colours, textSizes } from '../../../assets/theme';
import CrossSvg from '../../icons/Cross';
import { Span, A } from '../Text';
import Link from '../Link';
import { Box, CheckboxLabel, StyledRow as Row } from './styles';
import { useTheme } from 'styled-components';

const Checkbox = ({ checked, label, onChange, link, disabled, ...rest }) => {
  const theme = useTheme();
  const handleKeypress = ({ keyCode }) => {
    if (keyCode === 32) {
      onChange();
    }
  };
  return (
    <Row onClick={onChange} tabIndex={0} onKeyDown={handleKeypress} $disabled={disabled} {...rest} data-hoverable>
      <Box checked={checked} data-hoverable>
        {checked && <CrossSvg size={16} colour={theme.background} />}
      </Box>
      <Span>
        {label && <CheckboxLabel>{label}</CheckboxLabel>}
        {link && (
          <A italic size={textSizes.sm} href={link.to}>
            {link.label}
          </A>
        )}
      </Span>
    </Row>
  );
};

Checkbox.propTypes = {};

export default Checkbox;
