import React from 'react';
import { oneOf, bool, string, number, oneOfType, node } from 'prop-types';
import { withTheme } from 'styled-components';

import { colours, fontFamilies } from '../../../assets/theme';

import { allCases, caseText, splitChildrenToTag } from './helpers';
import { TextBase } from './styles';

const Text = withTheme(
  ({
    tag = 'span',
    letterCase,
    weight = 'normal',
    theme,
    colour,
    lineHeight = 1.2,
    italic = false,
    splitBy,
    fontFamily = fontFamilies[0],
    children,
    outline = false,
    size,
    selectable = true,
    ...rest
  }) => {
    const colourDefault = colour ?? theme?.foreground ?? colours.white;

    return (
      <TextBase
        colour={colourDefault}
        as={tag}
        weight={weight}
        lineHeight={lineHeight}
        italic={italic}
        $fontFamily={fontFamily}
        outline={outline}
        $size={size}
        selectable={selectable}
        {...rest}
      >
        {splitBy ? splitChildrenToTag(caseText(children, letterCase)) : caseText(children, letterCase)}
      </TextBase>
    );
  },
);

Text.propTypes = {
  tag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'a', 'li', 'div', 'small', 'q', 'label']),
  weight: oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    // '800',
    // '900',
    'bolder',
    'lighter',
    'normal',
    'inherit',
  ]),
  italic: bool,
  colour: string,
  align: string,
  size: oneOfType([string, number]),
  lineHeight: number,
  letterCase: oneOf([...allCases]),
  fontFamily: oneOfType([string, oneOf([0, 1])]),
  splitBy: string,
  children: node,
  outline: bool,
  selectable: bool,
};

export const H1 = ({ children, ...rest }) => (
  <Text tag="h1" weight="700" {...rest}>
    {children}
  </Text>
);

export const H2 = ({ children, ...rest }) => (
  <Text tag="h2" weight="700" {...rest}>
    {children}
  </Text>
);
export const H3 = ({ children, ...rest }) => (
  <Text tag="h3" weight="600" {...rest}>
    {children}
  </Text>
);
export const H4 = ({ children, ...rest }) => (
  <Text tag="h4" {...rest}>
    {children}
  </Text>
);
export const H5 = ({ children, ...rest }) => (
  <Text tag="h5" {...rest}>
    {children}
  </Text>
);
export const H6 = ({ children, ...rest }) => (
  <Text tag="h6" {...rest}>
    {children}
  </Text>
);
export const P = ({ children, ...rest }) => (
  <Text tag="p" {...rest}>
    {children}
  </Text>
);
export const Span = ({ children, ...rest }) => (
  <Text tag="span" size="inherit" weight="inherit" {...rest}>
    {children}
  </Text>
);
export const Label = ({ children, ...rest }) => (
  <Text tag="label" {...rest}>
    {children}
  </Text>
);
export const A = withTheme(({ children, theme, ...rest }) => (
  <Text tag="a" colour={theme?.highlight} data-hoverable {...rest}>
    {children}
  </Text>
));
export const Li = ({ children, ...rest }) => (
  <Text tag="li" {...rest}>
    {children}
  </Text>
);
export const Div = ({ children, ...rest }) => (
  <Text tag="div" {...rest}>
    {children}
  </Text>
);

export default Text;
