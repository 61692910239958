import React from 'react';
import { number, string } from 'prop-types';
import { colours } from '../../assets/theme';

const CrossSvg = ({ size = '24', colour = colours.black, weight = 'light', ...rest }) => (
  <svg viewBox="0 0 24 24" height={size} width={size} {...rest}>
    {weight === 'light' ? (
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <line x1="17.455" x2="6.545" y1="17.5" y2="6.5" stroke={colour} />
        <line x1="17.455" x2="6.545" y1="17.5" y2="6.5" stroke={colour} transform="matrix(-1 0 0 1 24 0)" />
      </g>
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4334 20.188L16.1184 11.979L24.3184 3.697L20.6214 0L12.4094 8.318L4.09941 0.115L0.433411 3.781L8.75441 12.021L0.548411 20.334L4.21441 24L12.4514 15.682L20.7364 23.885L24.4334 20.188Z"
        fill={colour}
      />
    )}
  </svg>
);

CrossSvg.propTypes = {
  size: number,
  colour: string,
};

export default CrossSvg;
