import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: ${props => props.justify ?? 'flex-start'};
  align-items: ${props => props.align ?? 'flex-start'};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  justify-content: ${props => props.justify ?? 'flex-start'};
  align-items: ${props => props.align ?? 'flex-start'};
`;
