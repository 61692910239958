import React from 'react';
import { colours, textSizes } from '../../../assets/theme';
import CrossSvg from '../../icons/Cross';
import { Span, A, Label } from '../Text';
import Link from '../Link';
import { Radio, OptionLabel, StyledRow as Row, GroupLabel } from './styles';
import { useTheme } from 'styled-components';
import { Column } from '../../Layout/Flex';

const RadioGroup = ({ value, label, options, disabled, onChange, column, ...rest }) => {
  const theme = useTheme();
  const handleKeypress = ({ keyCode }, value) => {
    if (keyCode === 32) {
      onChange(value);
    }
  };


  return (
  <Column>
  {label ? <GroupLabel weight="bold">{label}</GroupLabel> : null}
  {/* <Row> */}

  {options?.map ? options.map(option => 
   (<Row key={option.value} onClick={() => {
     onChange(option.value)
    }} tabIndex={0} onKeyDown={(e) => handleKeypress(e, option.value)} $disabled={disabled} {...rest} data-hoverable>
      <Radio checked={value === option.value} data-hoverable />
        {option.label && <OptionLabel>{option.label}</OptionLabel>}
    </Row>
    )) : null}
    {/* </Row> */}
        </Column>
  );
};

export default RadioGroup;
