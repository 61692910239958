import styled from 'styled-components';
import Link from '../../atoms/Link';
import { colours } from '../../../assets/theme';

export const Main = styled.main`
  margin: 0;
  /* padding: 0 24px; */
`;

export const SideLink = styled(Link)`
  position: fixed;
  right: ${props => (props.horizontalPosition === 'right' ? '2px' : 'auto')};
  left: ${props => (props.horizontalPosition === 'left' ? '2px' : 'auto')};
  transform: ${({ verticalPosition, horizontalPosition }) =>
    getTransform(verticalPosition, horizontalPosition)};
  transform-origin: bottom
    ${props => (props.verticalPosition === 'bottom' ? 'center' : props.horizontalPosition)};
  top: ${props => (props.verticalPosition === 'top' ? 'calc(100vh / 6)' : 'auto')};
  bottom: ${props => (props.verticalPosition === 'top' ? 'auto' : 'calc(100vh / 3)')};
  white-space: nowrap;
  z-index: 6;
`;

const getChatButtonPosition = (playbackPlayerOpen, livestreamIsLive) => {
  if (livestreamIsLive) return 0;
  if (playbackPlayerOpen) return '60px';
  return '50px';
};

export const LinkBoxRow = styled.div`
  position: fixed;
  right: 10px;
  display: flex;
  flex-direction: row;
bottom: ${props => getChatButtonPosition(props.playbackPlayerOpen, props.livestreamIsLive)};
`

export const LinkBox = styled.div`
  border-top: 1px solid ${props => props.theme?.foreground ?? colours.white};
  border-left: 1px solid ${props => props.theme?.foreground ?? colours.white};
  border-right: 1px solid ${props => props.theme?.foreground ?? colours.white};
  background-color: ${props => props.theme?.background ?? colours.black};
  display: flex;
  align-items: center;
  margin-right: 12px;
  z-index: 1;
  padding: ${({ padded }) => padded ? '0 8px' : 0};
`;

const getTransform = (verticalPosition, horizontalPosition) => {
  let translateX = '0';
  let rotate = '0';

  if (verticalPosition === 'bottom') {
    translateX = horizontalPosition === 'left' ? '-50%' : '50%';
  }

  rotate = horizontalPosition === 'left' ? '90deg' : '-90deg';

  return `translateX(${translateX}) rotate(${rotate})`;
};
