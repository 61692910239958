import React from 'react';
import { number, string } from 'prop-types';

const Play = ({ size = '24', colour = 'inherit', ...rest }) => (
  <svg viewBox="0 0 24 24" height={size} width={size} {...rest}>
    <path
      fill={colour}
      d="M2.12436,1.73205 C2.12436,0.96225 2.95769,0.481125 3.62436,0.866025 L21.6244,11.2583 C22.291,11.6432 22.291,12.6055 21.6244,12.9904 L3.62436,23.3827 C2.95769,23.7676 2.12436,23.2865 2.12436,22.5167 L2.12436,1.73205 Z"
    />
  </svg>
);

Play.propTypes = {
  size: number,
  colour: string,
};

export default Play;
